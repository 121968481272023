.Inventory {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E33737;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.Inventory-header {
  min-height: 13vmin;
  width: 100vw;
  font-size: calc(20px + 2vmin);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  margin-bottom: 0;
}

.table-container-I {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.tableWrapper-I {
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
}

.tableWrapper-I th,
.tableWrapper-I td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.tableWrapper-I th {
  background-color: #d41f1f;
  color: white;
}

.table-body-container-I {
  max-height: 300px;
  overflow-y: auto;
  display: block;
}

/* th {
  background-color: #d41f1f;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 -2px 0 rgba(0, 0, 0, 0.05) inset;
} */

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin-top: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  padding: 5px;
  font-size: 14px;
  width: 200px;
}

.dropdown {
  width: 200px;
}

.submitBtn {
  background-color: #ffffff;
  color: #d41f1f;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.submitBtn:hover {
  background-color: #d4d4d4;
}