.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App {
  background-color: #E33737;
  align-content:center ;
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.App-header {
  min-height: 13vmin;
  width: 100vw;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
}

.image{
  width:20%;
  height:20%;
  margin-top: 5%;
  margin-bottom: 7%;
}

.button{
    background-color: #AA9798;
    border-radius: 25px;
    width:70vw;
    height:160px;
    border-width: 1;
    margin-bottom: 5%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: calc(8px + 2vmin);
    color:white;
    font-weight: bold;
    padding: 70px;
}
.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}