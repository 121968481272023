.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  background-color: #E33737;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
  padding: 0 2rem;
}

.App-header {
  min-height: 13vmin;
  width: 100vw;
  font-size: calc(20px + 2vmin);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  position: absolute;
  top: 0;
}

/* .homeContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0%;
  width: 100%;
  max-width: 90%;
} */

.image {
  width: 15%;
  height: 15%;
  margin-bottom: 0%;
  position: absolute;
  top: 12%;
  left: 43%;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0%;
  width: 100%;
  position: absolute;
  right:0%;
  top: 50%;
  height: 10%;
  
}

.amplify-flex {
  background-color: #E33737;
  align-content: center;
}

.amplify-visually-hidden {
  color: white;
  font-weight: bold;
}
.amplify-label {
  color: white;
  font-weight: 200;
}
.amplify-flex > amplify-field{
  background-color: #AA9798;
}
.amplify-tabs {
  background-color: white;
}
.amplify-button {
  background-color: #AA9798;
  border-width: 0;
  border-radius: 12px;
  margin-right: 10px;
  margin-left:10px;
  padding: 7px;
  margin-top:10px;
}

.amplify-tabs-item:nth-of-type(2) {
  display: none;
}


.div > button {
  background-color: #AA9798;
}
.section:not(*) { color: gray } 
.data-amplify-router-content {
  align-items: center;
  align-content: center;
  justify-content:center;
}
.amplify-tabs-item {
  background-color: #AA9798;
  border-width: 0;
  border-radius: 12px;
  margin-right: 10px;
  margin-left:10px;
  padding: 7px;
  margin-top:10px;
}
.signOutButton {
  background-color: white;
  border-radius: 25px;
  width: 10vw;
  height: 40px;
  border-width: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: calc(8px + 1.5vmin);
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:15%;
  right:5%;
}

.button {
  background-color: #AA9798;
  border-radius: 25px;
  width: 40vw;
  height: 80px;
  border-width: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: calc(8px + 1.5vmin);
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #927373;
}

.signOutButton:hover {
  background-color: #b9b9b9;
}

.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
